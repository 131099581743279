import React from "react";

const ProductRequestButton = ({ label, destination }) => (
  <button
    className='bg-cadt hover:bg-cadt-hover rounded-button'
    onClick={destination}
  >
    {label}
  </button>
);

export default ProductRequestButton;
