import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import { GatsbyImage } from "gatsby-plugin-image";

const ProductSlideshow = ({ photos, title }) => {
  const swiperSettings = {
    pagination: { dynamicBullets: true },
    autoplay: { delay: 4000, disableOnInteraction: false },
    modules: [Autoplay, Pagination],
  };

  return (
    <div className='sm:order-1 xl:w-3/4 order-first w-full h-full'>
      <Swiper {...swiperSettings} className='mySwiper'>
        {photos.map((photo, i) => (
          <SwiperSlide key={i}>
            <GatsbyImage
              image={photo.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductSlideshow;
