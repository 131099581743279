import React from "react";
import ColorExternalButton from "../../Global/Buttons/ColorExternalButton";
import ProductRequestButton from "./ProductRequestButton";

const ProductDescription = ({ content, button }) => {
  return (
    <div className='justify-self-end xl:w-3/4 flex flex-col justify-between'>
      <div>
        <h2 className=' font-bold'>{content.title}</h2>
        <div
          className='sm:prose-h4:pt-14 prose-h4:pt-6 prose-h4:pb-5 prose-h4:font-light sm:text-sm pt-4 text-base prose'
          dangerouslySetInnerHTML={{ __html: content.text }}
        ></div>
      </div>
      <div className='sm:block flex justify-center'>
        {typeof button.destination === "string" ? (
          <ColorExternalButton
            label={button.label}
            destination={button.destination}
          />
        ) : (
          <ProductRequestButton
            label={button.label}
            destination={button.destination}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDescription;
