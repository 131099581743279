import React, { useState, useEffect } from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";

import ProductFormHeader from "./ProductFormHeader";
import ProductFormBody from "./ProductFormBody";

import ColorOnClickButton from "./../../Global/Buttons/ColorOnClickButton";
import CloseX from "../../Icons/CloseX";

const ProductForm = ({ form, setOpenForm }) => {
  const { t } = useTranslation();

  const [thankYou, setThankYou] = useState(null);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") setOpenForm(false);
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [setOpenForm]);

  return (
    <div className='bg-neutral-700/70 text-neutral-600 fixed z-40 flex items-center justify-center w-screen h-screen'>
      <div className='bg-neutral-100 form-size relative'>
        <button
          className='right-2 top-2 hover:text-neutral-800 absolute p-1 cursor-pointer'
          onClick={() => setOpenForm(null)}
        >
          <CloseX />
        </button>

        {thankYou ? (
          <div className='flex flex-col items-center'>
            <ProductFormHeader
              title={t("TR_THANK_YOU_TITLE", { extra: thankYou })}
              subtitle={t("TR_THANK_YOU_DESCRIPTION")}
            />
            <ColorOnClickButton
              label={t("TR_THANK_YOU_BUTTON")}
              onClickFn={setOpenForm}
              fnValue={false}
            />
          </div>
        ) : (
          <>
            <ProductFormHeader
              title={form.header.title}
              subtitle={form.header.subtitle}
            />
            <ProductFormBody
              formContent={form.content}
              topic={form.header.title}
              setThankYou={setThankYou}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductForm;
