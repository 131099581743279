import React, { useState } from "react";
import { Formik, Form } from "formik";

import FormCheckboxPrivacy from "../../Global/FormComponents/FormCheckboxPrivacy";
import FormInput from "../../Global/FormComponents/FormInput";
import FormTextArea from "../../Global/FormComponents/FormTextArea";
import FormSubmitButton from "../../Global/FormComponents/FormSubmitButton";

import {
  initialValues,
  useValidationSchema,
} from "../../Hooks/useValidationSchemaProducts";

const ProductFormBody = ({ formContent, setThankYou, topic }) => {
  const { name, company, email, message, privacy, submit } = formContent;

  const [formSubmitted, setFormSubmitted] = useState(false);

  const validationSchema = useValidationSchema();

  const encode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  return (
    <Formik
      initialValues={initialValues(topic)}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setFormSubmitted(true);
        fetch("/", {
          body: encode({ "form-name": "products", ...values }),
          method: "POST",
        })
          .then(() => {
            setThankYou(values.name);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }}
    >
      <Form
        className='flex flex-col gap-2 pt-4 mb-4'
        name='products'
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        encType='multipart/form-data'
      >
        <input type='hidden' name='form-name' value='products' />
        <input type='hidden' name='subject' value='' />
        <input type='hidden' name='bot-field' />
        <FormInput
          required={name.required}
          name={name.id}
          type={name.type}
          label={name.label}
        />
        <FormInput
          required={company.required}
          name={company.id}
          type={company.type}
          label={company.label}
        />
        <FormInput
          required={email.required}
          name={email.id}
          type={email.type}
          label={email.label}
        />

        <FormTextArea placeholder={message.label} name={message.id} />

        <FormCheckboxPrivacy name={privacy.id} label={privacy.label} />

        <div className='flex justify-end'>
          <FormSubmitButton info={submit} formSubmitted={formSubmitted} />
        </div>
      </Form>
    </Formik>
  );
};

export default ProductFormBody;
