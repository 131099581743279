import React from "react";

const ProductFormHeader = ({ title, subtitle }) => {
  return (
    <div className='flex flex-col items-center gap-5'>
      <h2 className={`text-neutral-700 font-bold text-xl text-center`}>
        {title}
      </h2>
      <h4 className={`text-neutral-700 text-sm text-left`}>{subtitle}</h4>
    </div>
  );
};

export default ProductFormHeader;
