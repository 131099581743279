import React from "react";

const ColorExternalButton = ({ label, destination, color }) => {
  const bgColor = color ? `bg-${color}` : "bg-cadt";
  const hoverColor = color ? `hover:bg-${color}-hover` : "hover:bg-cadt-hover";

  return (
    <a href={destination} target='_blank' rel='noreferrer noopener'>
      <button className={`${bgColor} ${hoverColor} rounded-button`}>
        {label}
      </button>
    </a>
  );
};

export default ColorExternalButton;
