import * as Yup from "yup";
import useErrorMessages from "./useErrorMessages";

export const initialValues = (topic) => ({
  subject: "Product request on CADT.com",
  topic,
  name: "",
  company: "",
  email: "",
  message: "",
  privacy: false,
});

export const useValidationSchema = () => {
  const err = useErrorMessages();
  return Yup.object({
    name: Yup.string().required(err.required).min(3, err.minLength(3)).max(50),
    company: Yup.string(),
    email: Yup.string().email(err.email).required(err.required),
    message: Yup.string()
      .required(err.requiredTextArea)
      .min(3, err.minLength(3))
      .max(500, err.maxLength(500)),
    privacy: Yup.boolean()
      .required(err.required)
      .oneOf([true], err.requiredPrivacy),
  });
};
