import React, { useState } from "react";
import { graphql } from "gatsby";

import PageHeader from "../components/Global/PageHeader";
import ProductForm from "../components/Products/ProductForm/ProductForm";
import ProductPage from "../components/Products/ProductPage/ProductPage";

import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";

const ProductDetails = ({ data }) => {
  const { t } = useTranslation();

  const [openForm, setOpenForm] = useState(false);

  const productPageData = {
    content: {
      title: data.markdownRemark.frontmatter.title,
      text: data.markdownRemark.html,
    },
    button: {
      label: data.markdownRemark.frontmatter.buttonLabel,
      destination: data.markdownRemark.frontmatter.buttonLink
        ? data.markdownRemark.frontmatter.buttonLink
        : () => {
            setOpenForm(true);
          },
    },
    photos: data.markdownRemark.frontmatter.images,
  };

  const productPageForm = {
    header: {
      title: data.markdownRemark.frontmatter.title,
      subtitle: t("TR_PRODUCTS_FORM_SUBTITLE"),
    },
    content: {
      name: {
        id: "name",
        label: t("TR_FORM_NAME"),
        required: true,
        type: "text",
      },
      company: {
        id: "company",
        label: t("TR_FORM_COMPANY"),
        required: false,
        type: "text",
      },
      email: {
        id: "email",
        label: t("TR_FORM_EMAIL"),
        required: true,
        type: "email",
      },
      message: {
        id: "message",
        label: t("TR_PRODUCTS_FORM_MESSAGE"),
      },
      privacy: {
        id: "privacy",
        label: {
          accept: t("TR_FORM_PRIVACY_ACCEPT"),
          privacy: t("TR_FORM_PRIVACY"),
        },
        required: true,
      },
      submit: { id: "send", label: t("TR_FORM_SEND") },
    },
  };

  return (
    <div className='relative'>
      {productPageForm && openForm && (
        <ProductForm form={productPageForm} setOpenForm={setOpenForm} />
      )}
      <Layout>
        <PageHeader pageTitle={t("TR_PRODUCTS_PAGE_TITLE")} />
        {productPageData.content &&
          productPageData.button &&
          productPageData.photos && (
            <ProductPage
              content={productPageData.content}
              button={productPageData.button}
              photos={productPageData.photos}
            />
          )}
      </Layout>
    </div>
  );
};

export default ProductDetails;

export const query = graphql`
  query productDetails($slug: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    markdownRemark(
      frontmatter: { slug: { eq: $slug }, language: { eq: $language } }
    ) {
      html
      id
      frontmatter {
        buttonLabel
        buttonLink
        title
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const title = data.markdownRemark.frontmatter.title;
  return (
    <>
      <title>{title} | CADT</title>
    </>
  );
};
