import React from "react";
import ProductDescription from "./ProductDescription";
import ProductSlideshow from "./ProductSlideshow";

const ProductPage = ({ content, photos, button }) => (
  <div className='pt-14 sm:grid sm:grid-cols-2 sm:px-10 px-7 sm:gap-10 flex flex-col gap-5 pb-32'>
    <ProductDescription content={content} button={button} />
    <ProductSlideshow photos={photos} title={content.title} />
  </div>
);

export default ProductPage;
